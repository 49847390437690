import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import axios from 'axios';
import './PaymentForm.css';

const PaymentForm = ({ category, onClose }) => {
  const [amount, setAmount] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isRecurring, setIsRecurring] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (amount > 0) {
      if (clientSecret) {
        cancelPaymentIntent(clientSecret);
      }
      fetchPaymentIntentClientSecret();
    }
  }, [amount]);

  const fetchPaymentIntentClientSecret = async () => {
    try {
      console.log('fetchPaymentIntentClientSecret initiated');
      const baseUrl = 'https://us-central1-themc-d23c7.cloudfunctions.net/api';

      const response = await axios.post(`${baseUrl}/create-payment-intent`, {
        amount: amount * 100,
        currency: 'usd',
        payment_method_types: ['card'],
        category: category.name,
        isRecurring
      });

      console.log('Response status:', response.status);
      console.log("Response data:", response.data);

      if (response.status !== 200) {
        throw new Error(`Server error: ${response.data.error}`);
      }

      const { clientSecret } = response.data;
      console.log('Client secret fetched:', clientSecret);
      setClientSecret(clientSecret);
    } catch (error) {
      console.error('Error fetching client secret:', error);
    }
  };

  const cancelPaymentIntent = async (clientSecret) => {
    try {
      const baseUrl = 'https://us-central1-themc-d23c7.cloudfunctions.net/api';
      
      console.log('Cancelling previous payment intent:', clientSecret);
      const paymentIntentId = clientSecret.split('_secret')[0];
      await axios.post(`${baseUrl}/cancel-payment-intent`, {
        paymentIntentId
      });
  
      console.log('Previous payment intent cancelled');
    } catch (error) {
      console.error('Error cancelling previous payment intent:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements || !clientSecret) {
      setLoading(false);
      return;
    }

    if (amount === 0) {
      alert('Please enter a valid amount to donate.');
      setLoading(false);
      return;
    }

    if ((name === '' || email === '') && !isAnonymous) {
      alert('Please enter your name and email to proceed, or make the donation anonymous.');
      setLoading(false);
      return;
    }

    const billingDetails = isAnonymous
      ? { name: 'Anonymous Donor', email: 'anonymousdonor@gmail.com' }
      : { name, email };

    if (isRecurring) {
      await handleRecurringPayment(billingDetails);
    } else {
      await handleOneTimePayment(billingDetails);
    }

    setLoading(false);
  };

  const handleOneTimePayment = async (billingDetails) => {
    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      }
    });

    if (error) {
      console.error('Payment confirmation error:', error);
      alert('Payment failed: ' + error.message);
    } else if (paymentIntent.status === 'succeeded') {
      await updateDonationAmountInFirestore(Number(amount));
      alert('Thank you for your donation!');
      onClose();
    }
  };

  const handleRecurringPayment = async (billingDetails) => {
    try {
      const baseUrl = 'https://us-central1-themc-d23c7.cloudfunctions.net/api';

      const setupIntentResponse = await axios.post(`${baseUrl}/create-payment-intent`, {
        amount: amount * 100,
        currency: 'usd',
        isRecurring: true,
        category: category.name,
        email: billingDetails.email,
        name: billingDetails.name,
      });

      console.log('Setup Intent response:', setupIntentResponse);

      if (setupIntentResponse.status !== 200) {
        throw new Error(`Server error: ${setupIntentResponse.data.error}`);
      }

      const { setupIntentClientSecret, customerId } = setupIntentResponse.data;

      const { error: setupError, setupIntent } = await stripe.confirmCardSetup(setupIntentClientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: billingDetails,
        }
      });

      if (setupError) {
        throw setupError;
      }

      const subscriptionResponse = await axios.post(`${baseUrl}/create-subscription`, {
        customerId,
        paymentMethodId: setupIntent.payment_method,
        amount: amount * 100,
        currency: 'usd',
        metadata: {
          category: category.name,
        },
        category: category.name,
        email: billingDetails.email,
        name: billingDetails.name,
      });

      if (subscriptionResponse.status !== 200) {
        throw new Error(`Server error: ${subscriptionResponse.data.error}`);
      }

      await updateDonationAmountInFirestore(Number(amount));
      alert('Thank you for your recurring donation!');
      onClose();
    } catch (error) {
      console.error('Error creating subscription:', error);
      alert('Subscription failed: ' + error.message);
    }
  };

  const updateDonationAmountInFirestore = async (donationAmount) => {
    const donationDocRef = doc(db, 'donationCategories', category.id);
    await updateDoc(donationDocRef, {
      currentAmount: category.currentAmount + donationAmount
    });
  };

  return (
    <div className="payment-form">
      <h2>Donate to {category.name}</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Amount"
          required
        />
        {!isAnonymous && (
          <>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
              required
            />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
          </>
        )}
        <div>
          <label>
            <input
              type="checkbox"
              checked={isRecurring}
              onChange={(e) => setIsRecurring(e.target.checked)}
            />
            Make this a monthly donation
          </label>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={isAnonymous}
              onChange={(e) => setIsAnonymous(e.target.checked)}
            />
            Make this donation anonymous
          </label>
        </div>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Processing...' : 'Donate'}
        </button>
      </form>
      <button onClick={onClose}>Cancel</button>
    </div>
  );
};

export default PaymentForm;
