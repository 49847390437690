import React, { useState, useEffect } from 'react';
import './JummahTime.css';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

// Import all images
import ali from '../assets/ali.png';
import anas from '../assets/anas.png';
import derrick from '../assets/derrick.png';
import fahad from '../assets/fahad.png';
import hassan from '../assets/hassan.png';
import inshirah from '../assets/inshirah.png';
import jihad from '../assets/jihad.png';
import mamdouh from '../assets/mamdouh.png';
import noImage from '../assets/noImage.png';
import noKhutbah from '../assets/noKhutbah.png';
import nushaier from '../assets/nushaier.png';
import ramy from '../assets/ramy.png';

const imageMap = {
  ali, anas, derrick, fahad, hassan, inshirah, jihad, mamdouh, noImage, noKhutbah, nushaier, ramy
};

const JummahTime = () => {
  const [khutbahs, setKhutbahs] = useState([]);

  useEffect(() => {
    const fetchKhutbahs = async () => {
      const docRef = doc(db, 'Jummuah', 'khutbahs');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setKhutbahs(docSnap.data().masjids);
      } else {
        console.log('No such document!');
      }
    };

    fetchKhutbahs();
  }, []);

  return (
    <div className="jummah-container">
      <h1>Jummah Khutbahs</h1>
      {khutbahs.map((masjid, index) => (
        <div key={index} className="masjid">
          <div className="masjid-name">
            <h2>{masjid.name}</h2>
            {masjid.address && masjid.address.map((line, idx) => (
              <p key={idx}>{line}</p>
            ))}
          </div>
          <div className="khutbah-details">
            <div className="khateebs-and-times">
              {masjid.khateebs && masjid.khateebs.map((khateeb, idx) => (
                <div key={idx} className="khateeb-time-pair">
                  <div className="khateeb">
                    <img 
                      src={imageMap[khateeb.image] || noImage} 
                      alt={khateeb.name} 
                      className="khateeb-image"
                    />
                    <p>{khateeb.name}</p>
                  </div>
                  {masjid.times && masjid.times[idx] && (
                    <p className="time">{masjid.times[idx]}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default JummahTime;
