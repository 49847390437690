import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import logo from '../assets/tmc.jpg';
import FlipWords from '../components/FlipWords';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { Coordinates, CalculationMethod, PrayerTimes } from 'adhan';
import moment from 'moment-timezone';

const Home = () => {
  const words = ['Faith', 'Community', 'Knowledge', 'Love', 'Unity', 'Peace'];
  const [showAdhan, setShowAdhan] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [prayerData, setPrayerData] = useState({
    currentPrayer: '',
    nextPrayer: '',
    nextPrayerTime: null,
    iqamaTimes: {},
    adhanTimes: {},
  });

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setShowAdhan((prev) => !prev);
        setIsTransitioning(false);
      }, 500); // Half of the transition duration
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const fetchData = async () => {
    try {
      // Fetch Iqama times from Firestore
      const iqamaDocRef = doc(db, 'settings', 'salahTimes');
      const iqamaDocSnap = await getDoc(iqamaDocRef);

      let iqamaTimes = {};
      if (iqamaDocSnap.exists()) {
        iqamaTimes = iqamaDocSnap.data();
      }

      // Calculate Adhan times using Adhan library
      const coordinates = new Coordinates(28.035792, -82.391538); // Example coordinates
      const params = CalculationMethod.NorthAmerica();
      const date = new Date();
      const prayerTimes = new PrayerTimes(coordinates, date, params);

      // Get Adhan times
      const adhanTimes = {
        fajr: prayerTimes.fajr,
        dhuhr: prayerTimes.dhuhr,
        asr: prayerTimes.asr,
        maghrib: prayerTimes.maghrib,
        isha: prayerTimes.isha,
      };

      // Determine current and next prayer
      const now = new Date();
      const times = [
        { name: 'Fajr', time: prayerTimes.fajr },
        { name: 'Dhuhr', time: prayerTimes.dhuhr },
        { name: 'Asr', time: prayerTimes.asr },
        { name: 'Maghrib', time: prayerTimes.maghrib },
        { name: 'Isha', time: prayerTimes.isha },
      ];

      let currentPrayer = '';
      let nextPrayer = '';
      let nextPrayerTime = null;

      for (let i = 0; i < times.length; i++) {
        if (now < times[i].time) {
          currentPrayer = i > 0 ? times[i - 1].name : times[times.length - 1].name;
          nextPrayer = times[i].name;
          nextPrayerTime = times[i].time;
          break;
        }
      }

      if (!currentPrayer) {
        currentPrayer = times[times.length - 1].name;
        nextPrayer = times[0].name;
        nextPrayerTime = times[0].time;
      }

      // Store the data in state
      setPrayerData({
        currentPrayer,
        nextPrayer,
        nextPrayerTime,
        iqamaTimes,
        adhanTimes,
      });
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const formatTime = (time) => {
    return moment(time).tz('America/New_York').format('h:mm A');
  };

  const prayerTimes = [
    { name: 'Fajr', icon: '🌅' },
    { name: 'Dhuhr', icon: '☀️' },
    { name: 'Asr', icon: '🌤️' },
    { name: 'Maghrib', icon: '🌅' },
    { name: 'Isha', icon: '🌙' },
  ];

  return (
    <div className="home-container">
      <div className="home-content">
        <h1 className="home-title">The Muslim Connection</h1>
        <p className="home-description">
          Connecting Muslims through <FlipWords words={words} duration={800} />
        </p>
        <div className="home-buttons">
          <Link to="/about">
            <button className="home-button learn-more">Learn More</button>
          </Link>
          <Link to="/contact">
            <button className="home-button get-involved">Get Involved</button>
          </Link>
        </div>
        <div className="prayer-times">
          <h2 className={`prayer-times-title ${isTransitioning ? 'fade' : ''}`}>
            {showAdhan ? 'Adhan Times' : 'Iqama Times'}
          </h2>
          {prayerTimes.map((prayer) => (
            <div key={prayer.name} className="prayer-time">
              <span>{prayer.icon} {prayer.name}</span>
              <span className={`prayer-time-value ${isTransitioning ? 'fade' : ''}`}>
                {showAdhan
                  ? formatTime(prayerData.adhanTimes[prayer.name.toLowerCase()])
                  : prayerData.iqamaTimes[prayer.name.toLowerCase()] + (prayer.name === 'Fajr' ? ' AM' : ' PM')}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="home-image">
        <img src={logo} alt="TMC Logo" />
      </div>
    </div>
  );
};

export default Home;
