import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import PaymentForm from './PaymentForm';
import axios from 'axios';
import './DonationPage.css';

const DonationPage = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [email, setEmail] = useState('');
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [isSubscriptionsModalVisible, setIsSubscriptionsModalVisible] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'donationCategories'));
      const categoriesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoriesList);
    } catch (error) {
      console.error('Error fetching donation categories: ', error);
    } finally {
      setLoading(false);
    }
  };

  const openManageSubscriptionModal = () => {
    setIsEmailModalVisible(true);
  };

  const fetchSubscriptions = async () => {
    setIsLoadingSubscriptions(true);
    try {
      const response = await axios.post(
        'https://us-central1-themc-d23c7.cloudfunctions.net/api/get-subscriptions',
        { email }
      );

      if (response.status === 200) {
        console.log('Subscriptions: ', JSON.stringify(response.data.subscriptions, null, 2));
        setSubscriptions(response.data.subscriptions);
        setIsSubscriptionsModalVisible(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        alert('No subscriptions found for this email.');
      } else {
        console.error('Error fetching subscriptions:', error);
        alert('Failed to fetch subscriptions. Please try again.');
      }
    } finally {
      setIsLoadingSubscriptions(false);
      setIsEmailModalVisible(false);
    }
  };

  const cancelSubscription = async (email, subscriptionId) => {
    setIsCancelling(true);
    try {
      const response = await axios.post(
        'https://us-central1-themc-d23c7.cloudfunctions.net/api/cancel-subscription',
        {
          email: email,
          subscriptionId: subscriptionId,
        }
      );
      if (response.status === 200) {
        alert('Subscription cancelled successfully.');
        setSubscriptions(subscriptions.filter((sub) => sub.id !== subscriptionId));
      } else {
        alert('Failed to cancel subscription. Please try again.');
      }
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      alert('Failed to cancel subscription. Please try again.');
    } finally {
      setIsCancelling(false);
    }
  };

  return (
    <div className="donation-page">
      <h1>Support TMC Projects</h1>
      <p className="mobile-disclaimer">
        If you are using a mobile device, please use the TMC iOS/Android App to make donations.
      </p>
      <a href="https://linktr.ee/tmctampa" target="_blank" rel="noopener noreferrer" className="other-methods-button">
        Other Donation Methods
      </a>
      {loading ? (
        <p>Loading categories...</p>
      ) : (
        <div className="categories">
          {categories.map((category) => (
            <div key={category.id} className="category">
              <h2>{category.name}</h2>
              <p>{category.description}</p>
              <p>Target: ${category.targetAmount.toFixed(2)}</p>
              <p>Current: ${category.currentAmount.toFixed(2)}</p>
              <progress value={category.currentAmount} max={category.targetAmount} />
              <button onClick={() => setSelectedCategory(category)}>Donate</button>
            </div>
          ))}
        </div>
      )}
      {selectedCategory && (
        <PaymentForm category={selectedCategory} onClose={() => setSelectedCategory(null)} />
      )}
      <button onClick={openManageSubscriptionModal} className="manage-subscription-button">
        Manage Subscriptions
      </button>

      {isEmailModalVisible && (
        <div className="modal">
          <div className="modal-content">
            <h2>Enter your email</h2>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
            <button onClick={fetchSubscriptions}>Continue</button>
            <button onClick={() => setIsEmailModalVisible(false)}>Cancel Subscription</button>
          </div>
        </div>
      )}

      {isSubscriptionsModalVisible && (
        <div className="modal">
          <div className="modal-content">
            <h2>Your Subscriptions</h2>
            {isLoadingSubscriptions ? (
              <p>Loading subscriptions...</p>
            ) : subscriptions.length > 0 ? (
              <ul>
                {subscriptions.map((subscription) => (
                  <li key={subscription.id}>
                    <p>Product: {subscription.metadata.category}</p>
                    <p>Amount: ${(subscription.plan.amount / 100).toFixed(2)}</p>
                    <p>Next payment due: {new Date(subscription.current_period_end * 1000).toLocaleDateString()}</p>
                    <button onClick={() => cancelSubscription(email, subscription.id)} disabled={isCancelling}>
                      {isCancelling ? 'Cancelling...' : 'Cancel Subscription'}
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No active subscriptions found.</p>
            )}
            <button onClick={() => setIsSubscriptionsModalVisible(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DonationPage;
