import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';  // Make sure you have this import configured
import './Blog.css';

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  // Helper function to format the date
  const formatDate = (timestamp) => {
    // Check if the timestamp is a Firebase Timestamp
    if (timestamp && timestamp.seconds) {
      return new Date(timestamp.seconds * 1000).toLocaleDateString();
    }
    // If it's already a Date object or a valid date string
    const date = new Date(timestamp);
    return date instanceof Date && !isNaN(date) 
      ? date.toLocaleDateString()
      : 'Date not available';
  };

  // Helper function to convert URLs to clickable links
  const convertLinksToHTML = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);
    
    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a 
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            className="blog-link"
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const blogCollection = collection(db, 'blog');
        const blogSnapshot = await getDocs(blogCollection);
        const blogList = blogSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setBlogPosts(blogList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog posts: ", error);
        setLoading(false);
      }
    };

    fetchBlogPosts();
  }, []);

  return (
    <div className="blog-container">
      <h1>Our Blog</h1>
      <div className="blog-posts">
        {loading ? (
          <p>Loading blog posts...</p>
        ) : blogPosts.length > 0 ? (
          blogPosts.map(post => (
            <div className="blog-post" key={post.id}>
              <h2>{post.heading}</h2>
              <p className="blog-meta">
                By {post.author} • {formatDate(post.dateposted)}
              </p>
              <p>{convertLinksToHTML(post.body)}</p>
            </div>
          ))
        ) : (
          <p>No blog posts available.</p>
        )}
      </div>
    </div>
  );
};

export default Blog;
