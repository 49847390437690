import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../assets/tmc.jpg';

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <header>
      <div className="logo">
        <img src={logo} alt="TMC Logo" />
      </div>
      <button className="nav-toggle" onClick={toggleNav}>
        <span className="hamburger"></span>
      </button>
      <nav className={isNavOpen ? 'open' : ''}>
        <ul>
          <li><Link to="/" onClick={toggleNav}>Home</Link></li>
          <li className="dropdown">
            <Link to="/about" onClick={toggleDropdown}>About</Link>
            <ul className={`dropdown-menu ${dropdownOpen ? 'open' : ''}`}>
              <li><Link to="/about/who-are-we" onClick={toggleNav}>Who Are We</Link></li>
              <li><Link to="/about/mission" onClick={toggleNav}>Mission</Link></li>
              <li><Link to="/about/our-story" onClick={toggleNav}>Our Story</Link></li>
            </ul>
          </li>
          <li><Link to="/blog" onClick={toggleNav}>Blog</Link></li>
          <li><Link to="/contact" onClick={toggleNav}>Contact</Link></li>
          <li><Link to="/dawah" onClick={toggleNav}>Da'wah</Link></li>
          <li><Link to="/events" onClick={toggleNav}>Events</Link></li>
          <li><Link to="/jummah-time" onClick={toggleNav}>Jummah</Link></li>
          <li><Link to="/programs" onClick={toggleNav}>Programs</Link></li>
          <li><Link to="/ramadan" onClick={toggleNav}>Ramadan</Link></li>
          <li><Link to="/umrah-trip" onClick={toggleNav}>TMCUmrahTrip</Link></li>
          <li><Link to="/testimonials" onClick={toggleNav}>Testimonials</Link></li>
          
          <li><Link to="/donate" onClick={toggleNav}>Donate</Link></li>
          <li>
            <a 
              href="https://tampazakahfund.netlify.app/" 
              target="_blank" 
              rel="noopener noreferrer"
              onClick={toggleNav}
            >
              TampaZakahFund
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
